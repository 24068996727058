<template>
  <div :class="['home-wrapper',smooth == true ? 'smooth' : '']">
    <div class="home">
      <div class="content overflow-hidden" ref="map">
        <div class="row no-gutters desktop-content d-none d-lg-flex ">
          <div class="col-lg-6 d-flex align-items-center left-text-section bg-white">
              <div class="title-section w-100">
                <div class="header">
                  <h1 class="text-center">Hello</h1>
                  <h1 class="text-center">This is <span class="underline">Ankit</span></h1>
                </div>
                <h2 class="text-center">UX Designer @ Maropost</h2>
              </div>
          </div>
          <div class="col-lg-6 image">
            <img src="../assets/images/ankit-sharma.jpg" class="w-100" alt="">
          </div>
        </div>
        <div class="row no-gutters d-lg-none mobile-content">
          <div class="col-sm-12 col-md-12">
            <div class="bg-image">
                <div class="center-content">
                  <div class="title-section">
                    <div class="header">
                      <h1 class="text-center">Hello</h1>
                      <h1 class="text-center">This is <span class="underline">Ankit</span></h1>
                    </div>
                    <br>
                    <h2 class="text-center">UX Designer @ Maropost</h2>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <img class="swipe-icon d-lg-none img-fluid p-3" src="../assets/images/home_swipe_icon.svg" alt="">
    </div>
    <div class="side-wrapper">
      <projectsList/>
    </div>
  </div>
</template>
<script>
import projectsList from '@/components/project.vue';
import ScrollMagic from 'scrollmagic';
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { Flip } from "gsap/Flip";
import { PROJECTS_ROUTE } from '@/utils/routes';
import Draggable from "gsap/Draggable";

gsap.registerPlugin(ScrollTrigger);


export default {
  components: {
        projectsList
    },
  data() {
    return {
      isScreenSmall: false,
      smooth:false
    }
  },
  methods: {
    initDraggable() {
      // Draggable.create(this.$refs.map, {});
    },
    handleMediaQueryChange(event) {
      this.isScreenSmall = event.matches;
    },
    routeToProjectsPage() {
      this.$router.push({ name: PROJECTS_ROUTE })
    }
  },
  mounted() {
    class TypewriterEffect {
          constructor(element, speed) {
              this.element = element;
              this.text = element.getAttribute('data-text');
              this.speed = speed;
              this.currentText = '';
              this.currentIndex = 0;
              this.timer = null;
              this.isRunning = false;
          }

          startTypewriter() {
              if (!this.isRunning) {
                  this.isRunning = true;
                  this.typeWriter();
              }
          }

          typeWriter() {
              if (this.currentIndex < this.text.length) {
                  this.currentText += this.text.charAt(this.currentIndex);
                  this.element.textContent = this.currentText;
                  this.currentIndex++;
                  this.timer = setTimeout(() => this.typeWriter(), this.speed);
              } else {
                  this.isRunning = false;
              }
          }

          stopTypewriter() {
              clearTimeout(this.timer);
              this.isRunning = false;
              this.currentText = '';
              this.currentIndex = 0;
              this.element.textContent = '';
          }
      }

    const Typewriter = {
        typewriters: [],

        init(selector, speed) {
            const elements = document.querySelectorAll(selector);
            elements.forEach((element) => {
                const typewriter = new TypewriterEffect(element, speed);
                typewriter.startTypewriter();
                this.typewriters.push(typewriter);
            });
        },

        destroy() {
            this.typewriters.forEach(typewriter => typewriter.stopTypewriter());
            this.typewriters = [];
        }
      };
      
    let typewriterInitialized = false;
    if(window.screen.width>=1000 && document.querySelector('.home-wrapper')){
      gsap.to('.desktop-content', {
      scale: 80,
      ease: "Power1.easeInOut",
      scrollTrigger: {
        trigger: '.home-wrapper',
        start: "top top",
        end: "bottom bottom",
        scrub: 2, 
        anticipatePin: 1,
        onUpdate:(self)=>{
          this.smooth=self.progress>=0.55
          document.querySelector('.side-wrapper')?document.querySelector('.side-wrapper').style.visibility=(self.progress>=0.55)?"visible":"hidden":null;
          document.querySelector('.desktop-content')?document.querySelector('.desktop-content').style.visibility=(self.progress>=0.5)?"hidden":"visible":null;
          if(self.progress>=0.5){
            if(!typewriterInitialized){
              setTimeout(() => {
                  Typewriter.init('[data-text]', 50);
              }, 900);
            }
            typewriterInitialized = true;
          }
          else{
            if(typewriterInitialized) Typewriter.destroy();
            typewriterInitialized = false
          }
        }
      }
    }
    );
    }
    else{
      this.smooth=true
      Typewriter.init('[data-text]', 50);
    }
    
    this.mediaQuery = window.matchMedia('(max-width: 1000px)');
    this.mediaQuery.addEventListener('change', this.handleMediaQueryChange);
    this.handleMediaQueryChange(this.mediaQuery);

    this.initDraggable();
  },
  beforeDestroy() {
    this.mediaQuery.removeEventListener('change', this.handleMediaQueryChange);
  }
  // beforeRouteLeave(to, from, next) {
  //   gsap.to(this.$refs.map, {
  //     x: 0,  // Reset x position
  //     y: 0,  // Reset y position
  //     scale: 1, // Reset scale
  //     duration: 1,
  //     ease: "Power1.easeInOut",
  //     onComplete: () => {         
  //       next()
  //       window.location.reload();
  //     }
  //   });
  // }

}
</script>
<style scoped>
  body {
    overflow-y: hidden!important;
  }
  .desktop-content{
    height: 100vh;
  }
.home-wrapper{
  /* min-height: 150vh */
}
.desktop-content {
  transform-origin: 30.44vw 53.4vh;
}

.content .row .col-lg-6 {
  padding: 0px;
}

.content .header h1 {
  font-size: 5vw;
}

.content .header .underline {
  padding-bottom: 2px;
  border-bottom: 0.4vw solid #000000;
}

.content h2 {
  font-size: 2vw;
}

.d-lg-none .col-sm-12 {
  padding: 0px;
}

.bg-image {
  background-image: url('../assets/images/ankit-sharma.jpg'); /* Replace with your image URL */
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.center-content {
  text-align: center;
  width: 100%;
  min-height: 100dvh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: #FFFFFF;
  text-shadow: -2px 2px 1px #000;
  padding: 5rem 0.5rem;
}
.home .header{
  border: none;
  padding-top: 0;
  padding-bottom: 1.25vw;
  margin-bottom: 1.25vw;
}
.center-content .header h1 {
  font-size: 38px!important;
  font-weight: 400!important;
}

.center-content h2 {
  font-size: 22px;
  font-weight: 300!important;

}

.swipe-icon {
  position: absolute;
  left: 0;
  width: 60px;
  height: auto;
  bottom: 0;
}
.home-wrapper>.home{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.image{
  display: flex;
}
.image>img{
  object-fit: cover;
  object-position: center top;
}
@media (min-width: 1000px) {
  .home-wrapper{
    padding-top: 150vh;
  }
  .side-wrapper{
    visibility: hidden
    /* position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: none;
    background-color: #fff;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999; */
  }
}    
@media (max-width: 1000px) {    
  .home .header{
    padding-bottom: 0;
  }
  .content .header .underline {
    border-bottom: 4px solid #FFFFFF;
  }
  .home-wrapper>.home{
    position: static;
    height: auto;
  }
  .home{
    display: block !important;
  }
}
</style>
