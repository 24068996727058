import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/Home.vue'
import { PROJECTS_ROUTE, HOME_ROUTE, ABOUT_ME_ROUTE, MAROPOST_ROUTE, AUDIOBULB_ROUTE, OTHER_PROJECTS_ROUTE } from '@/utils/routes'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: HOME_ROUTE,
    component: HomeView
  },
  {
    path: '/maropost',
    name: MAROPOST_ROUTE,
    component: () => import('../views/Maropost.vue')
  },
  {
    path: '/audio-bulb',
    name: AUDIOBULB_ROUTE,
    component: () => import('../views/Audiobulb.vue')
  },
  {
    path: '/other-projects',
    name: OTHER_PROJECTS_ROUTE,
    component: () => import('../views/Other_Projects.vue')
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/'
  }
]

let isNavigatedFromInnerPage = false;

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.path === '/' && isNavigatedFromInnerPage) {
      return { top: 2000 };
    }
    return { top: 0 };
  }
})

router.beforeEach((to, from, next) => {
  if (from.path !== '/' && to.path === '/') {
    isNavigatedFromInnerPage = true; // Set flag when navigating from another page to '/'
  } else {
    isNavigatedFromInnerPage = false;
  }
  next();
})

router.afterEach((to, from) => {
  if (to.path === '/' && isNavigatedFromInnerPage) {
    window.location.reload(); // Refresh the page only when navigating from another page to '/'
  }
})

export default router
