  <template>
    <div class="content" ref="map">
        <div class="wrapper">
            <div class="header sidebar-header border-0">
                <div class="row no-gutters justify-content-center">
                  <div class="col-auto btn-col">
                      <button @mouseover="triggerDrop('projects')" @mouseleave="reverseDrop(PROJECTS_ROUTE)" v-on:click="showProject()" :class="['btn d-flex align-items-center text-center',tab == 'projects' ? 'selected' : '']"><span  data-text="Projects"></span></button>
                      <div :class="[ 'btn-projects text-center d-flex align-items-center', isDropped1 ? 'drop' : (isReversed1 ? 'reverse-drop' : ''), tab == 'projects' ? 'btn-projects-selected' : '']"><span v-if="tab == 'projects'" class="btn"></span></div>
                  </div>
                  <div class="col-auto btn-col">
                      <button @mouseover="triggerDrop('about')" @mouseleave="reverseDrop(ABOUT_ME_ROUTE)" v-on:click="showAbout()" :class="['btn d-flex align-items-center text-center',tab == 'about' ? 'selected' : '']"><span  data-text="About Me"></span></button>
                      <div :class="[ 'btn-projects', isDropped2 ? 'drop' : (isReversed2 && !isFirstLoad) ? 'reverse-drop' : '', tab == 'about' ? 'btn-projects-selected' : '']"><span v-if="tab == 'about'" class="btn"></span></div>
                  </div>
                </div>
            </div>
            <div v-show="tab == 'projects'"  class="sidebar-content project-content">
                <hr class="animation hr">
                <div v-on:click="routeToMaropost()" class="row m-0 project project-1">
                    <div class="col-12">
                        <div class="">
                            <h2 class="animation text" style="width: 100%" data-text="Maropost">&nbsp;</h2>
                        </div>
                    </div>
                </div>
                <hr class="animation hr">
                <div v-on:click="routeToAudiobulb()" class="row m-0 project project-2">
                    <div class="col-12">
                        <div class="">
                            <h2 class="animation text"><span data-text=" Audiobulb">&nbsp;</span><span class="small" data-text="(Case Study)"></span></h2>
                        </div>
                    </div>
                </div>
                <hr class="animation hr">
                <div v-on:click="routeToOtherProjects()" class="row m-0 project project-3">
                    <div class="col-12">
                        <div class="">
                            <h2 class="animation text" data-text="Other Projects">&nbsp;</h2>
                        </div>
                    </div>
                </div>
                <hr class="animation hr">
            </div>
            <div v-show="tab == 'about'" class="about-content sidebar-content">
                <p class="desc">Hey there! I'm Ankit, a Product Designer at <a class="underline-1 cursor-pointer" v-on:click="routeToMaropost()">Maropost</a>. With over 3 years of experience, I've honed my skills in Research, User Flow Designing, User Interface Designing and Prototyping. When I'm not designing, you can catch me singing my heart out.
                    <span class="underline-2 d-block">Design and music - that's my jam!</span></p>
                    <img class="abt d-none d-lg-block" src="../assets/images/about_img_lg.svg" alt="" srcset="">
                    <img class="p-0 col-11 mx-0 my-4 d-lg-none" src="../assets/images/about_img_sm.svg" alt="" srcset="">
                </div>
            </div>
        <customFooter/>
    </div>
</template>
<script>
import { gsap } from 'gsap'
import Splitting from 'splitting';
import 'splitting/dist/splitting.css';

import customFooter from '@/components/footer.vue'
import { MAROPOST_ROUTE, AUDIOBULB_ROUTE, OTHER_PROJECTS_ROUTE } from '@/utils/routes'

export default {
    components: {
        customFooter
    },
    data() {
        return {
            isDropped1: false,
            isReversed1: false,
            isDropped2: false,
            isReversed2: false,
            borderVisible: false,
            isFirstLoad: true,
            tab: 'projects'
        }
    },
    methods: {
        triggerDrop(tab) {
            if(this.tab != tab) { 
                if(this.tab != 'projects') {
                    this.isFirstLoad = false
                    this.isDropped1 = true;
                    this.isReversed1 = false;
                }
                if(this.tab != 'about') {
                    this.isDropped2 = true;
                    this.isReversed2 = false;
                }
            }
        },
        reverseDrop(tab) {
            if(this.tab != tab) { 
                if(this.tab != 'projects') {
                    this.isDropped1 = false;
                    this.isReversed1 = true;
                }
                if(this.tab != 'about') {
                    this.isDropped2 = false;
                    this.isReversed2 = true;
                }
            }
        },
        showProject() {
            gsap.to(".about-content", { 
                duration: 0.5,
                x: 1000,
                stagger: 0.05,
                onComplete: () => {
                    this.tab = 'projects'
                    if(this.tab != 'projects') {
                        this.isDropped1 = false;
                        this.isReversed1 = true;
                    }
                    if(this.tab != 'about') {
                        this.isDropped2 = false;
                        this.isReversed2 = true;
                    }
                }
            });
        },
        showAbout() {
            this.tab = 'about'
            if(this.tab != 'projects') {
                this.isDropped1 = false;
                this.isReversed1 = true;
            }
            if(this.tab != 'about') {
                this.isDropped2 = false;
                this.isReversed2 = true;
            }
            gsap.from(".about-content", { duration: 0.5, stagger: 0.05, x: 1000 }); 
            gsap.to(".about-content", { duration: 0.5, stagger: 0.05, x: 0 }); 
        },
        routeToMaropost() {
            this.$router.push({ name: MAROPOST_ROUTE })
        },
        routeToAudiobulb() {
            this.$router.push({ name: AUDIOBULB_ROUTE })
        },
        routeToOtherProjects() {
            this.$router.push({ name: OTHER_PROJECTS_ROUTE })
        }
    },
    mounted() {
        if(this.isFirstLoad) {
            if(this.tab != 'projects') {
                this.isDropped1 = false;
                this.isReversed1 = true;
            }
            if(this.tab != 'about') {
                this.isDropped2 = false;
                this.isReversed2 = true;
            }
        }


        Splitting();

        gsap.defaults({ ease: "none" });

        // Hide the item initially
        // gsap.set(".text", { opacity: 0, transformOrigin: "100% 50%" });

        // Create an animation to reveal the text elements from left to right after 2 seconds
        // gsap.set(".text-mask", { overflow: 'hidden' });
        // gsap.from(".text", { duration: 1.5, xPercent: -100, delay: 2 });
        if (document.querySelector(".text")) {
            gsap.from(".text .char", {
                duration: 1.5,
                delay: 1,
                opacity: 0,
                stagger: 0.02,
                y: 30,
                ease: "power1.out"
            });
        }
        // Create an animation to fade in the item after 2 seconds
        // gsap.from(".text", { opacity: 0, width: "0px", transformOrigin: "100% 50%" });
        // gsap.to(".text", { duration: 0.4, opacity: 1, width: "0px", delay: 1.0 });
    },
   
}
</script>
<style scoped>
/* navbar*/
.header .btn-projects {
    display: flex;
    position: absolute;
    pointer-events: none;
    top: 0;    
    justify-content: center;
    align-items: center;
    font-size: 2vw;
    background-color: transparent;
    border-radius: 5vw;
    height: 15vh;
    min-width: 18vw;
    border: none;
}
.btn-col+.btn-col{
    margin-left: 7.5vw;
}
.header .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    color: #000000;
    max-width: 100%;
    height: 15vh;
    min-width: 18vw;
    font-size: 6vh;
    position: relative;
    z-index: 1;
}

.header .btn-projects-selected {
    background-color: transparent;
    /* transition: 0.5s ease-out; */
}
.smooth .header .btn-projects-selected{
    animation: fill 0.5s forwards;
    animation-delay: 0.15s;
}
.header .btn.selected {color: #fff;}
.header .btn-projects:before{
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: rotate(90deg);
    border: 0.25vw solid #000000;
    border-radius: 5vw;
    transition: 0.75s all cubic-bezier(0.68, -0.55, 0.27, 1.55);
    transform-origin: top right;
}
.header .hide-border {
    border: 0.25vw solid transparent;
}

@keyframes fill {
    from{
        background-color: transparent;
    }
    to{
        background-color: #000;
    }
}
.header .btn-projects-selected .btn {
    color: #FFFFFF;
}
.header .btn-projects.btn-projects-selected:before{
    transition: none
}
.header .btn:hover+.btn-projects:before{
    transform: rotate(0);
    opacity: 1;
}
.sidebar-header {
    padding: 0;
}
.wrapper {
    padding-top: 5vh;
    overflow-x:hidden 
}
.sidebar-content{
    padding-top: 13vh;
    padding-bottom: 25vh;
}
@media (max-width: 1000px) {
    
    .header .btn-projects:before,
    .header .btn-projects {
        border-radius: 5rem;
        transform: none;
    }
    .header .btn-projects {
        width: 100%;
        height: 50px;
    }
    
    .header .btn {
        font-size: 22px;
        width: 100%;
        font-weight: 800;
        padding:10px 1rem;
        height: 50px;
    }
}


.hover-element {
    margin-bottom: 20px;
    padding: 10px 20px;
    background: #f0f0f0;
    border: 1px solid #ccc;
    cursor: pointer;
    text-align: center;
  }
  
  .box {
    width: 250px;
    height: 180px;
    background: #0ba;
    position: absolute;
    top: -200px; /* Position it off-screen initially */
  }


/* project*/

.content {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.project {
    padding: 1rem 1.5vw;
    background: linear-gradient(to left, #FFFFFF 50%, #000000 50%) right;
    background-size: 200%;
    transition: .5s ease-out;
    min-height: 20vh;
    display: flex;
    align-items: center;
}

hr {
    height: 0px;
    margin: 0px;
    width: 0;
    border-top: 1vh solid #000000;
}
.smooth hr{animation:line 1s forwards;}
.project:hover {
    cursor: pointer;
    background-color: #000000;
    color: #FFFFFF;
    background-position: left;
}

.project .small {
    font-size: 5vh;
    font-weight: 500;
}

.project h2 {
    font-size: 8vh;
    margin-bottom: 0;
    font-weight: 600;
}

@media (max-width: 1000px) {
    .project h2 {
        font-size: 32px;
    }

    .project .small {
        font-size: 20px;
        margin-left: 10px;
    }
    .project{
        min-height: unset;
    }
    .sidebar-content{
        padding-top: 2rem;
        padding-bottom: 7rem;
    }
}
@media (max-width: 480px) {
    .project{
        padding-left: 0rem;
    }
    .project h2 {
        font-size: 28px;
        .footer h2{
            margin-left: 0;
        }
    }

    .project .small {
        font-size: 16px;
        margin-left: 10px;
    }
}


/* about*/
.about-content{
    padding-left: 2vw;
    padding-right: 2vw;
}
.desc {
    font-size: 5vh;
    font-weight: 600;
}
.abt{
    margin:17vh 0 0;
    object-fit: contain;
    width: 100%;
    height: 22vh;
}

.desc .underline-1 {
    font-weight: 700;
    text-decoration: underline;
}

.desc .underline-2 {
    text-decoration: underline;
}

img {
    margin-left: 5%;
    width: 90%;
}
@media (max-width: 1000px) {
    .desc {
        font-size: 20px;
    }
    .home-wrapper .wrapper{
        flex: 1
    }
    .about-content{
        padding-left: 1rem;
        padding-right: 1rem;
    }
    hr{
        border-width: 1px;
    }
}
@media (max-width: 767px) {
    .sidebar-content{
        padding-bottom: 2rem;
    }
}

button:focus {
    outline: none;
    box-shadow: none;
}
</style>