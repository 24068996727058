<template>
  <div class="footer">
    <div class="row text-center">
      <div class="col-lg-3 d-flex align-items-center justify-content-center">
        <a href="mailto:aattriankit@gmail.com" class="h2" data-text="EMAIL"></a>
      </div>
      <div class="col-lg-3 d-flex align-items-center justify-content-center">
        <a href="https://www.linkedin.com/in/aankit-sharma/" class="h2" data-text="LINKEDIN" target="_blank"> 
        </a>
      </div>
      <div class="col-lg-3 d-flex align-items-center justify-content-center">
        <a href="https://cal.com/ankitatwork" target="_blank" class="h2" data-text="BOOK A CALL">
        </a>
      </div>
      <div
        class="col-lg-3 d-flex align-items-center justify-content-center copyright d-flex align-items-center"
      >
        <h3 class="text-center" data-text="&copy; 2024 Ankit Sharma"></h3>
      </div>
    </div>
  </div>
</template>

<style scoped>
.footer{
  position: relative;
}
.footer:before{
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 0;    
  animation:line 1s forwards;
  border-top: 0.25vw solid #000000;
}

.footer .h2 {
   font-size: 6vh;
  line-height: 1.25;
  display: inline-block;
  margin: 1vh 0;
  padding: 1vh 0;
  font-weight: 600;
  min-height: 7.5vh;
  display: inline;
  position: relative;
  cursor: pointer;
}

.footer .copyright {
  border-left: 0.25vw solid #000000;
}

.footer .copyright h3 {
  font-size: 4vh;
  margin-bottom: 0;
}
@media (max-width: 1000px) {
  .footer .h2 {
    padding: 0.5rem 0;
    font-size: 30px;
    font-weight: 700;
    text-align: left !important;
    margin-left: 20px;
  }

  .footer .copyright {
    margin-top: 10px;
    border-left: none;
    border-top: 1px solid #000000;
  }

  .text-center {
    text-align: left !important;
  }

  .col-lg-3 {
    justify-content: flex-start !important;
  }
}

@media (max-width: 480px) {
    .footer .h2 {
      margin-left: 0;
    }
    .footer .copyright {
      padding-left: 1rem;
    }
    .footer .copyright h3{
        font-size: 1rem;
    }
}
@media (min-width: 1000px) {
  .footer .h2::after {
    content: '';
    position: absolute;
    width: 0;
    border-top: 0.25vw solid #000000;
    bottom: 0;
    left: 0;
    transition: 0.25s ease-out;
  }

  .footer .h2:hover::after {
    width: 100%;
  }
}
</style>
